import io from "socket.io-client";
import * as CONSTANTS from '../constants/actions';

export default class Socket{
    constructor(dispatch){
        this.dispatch = dispatch;
        this.socket = io(process.env.REACT_APP_SOCKET_URL || "http://localhost:5005", {transports: ['websocket']});
        this.socket.on('Initial_Data', (data) => {
            console.log(data);
            this.dispatch({type: CONSTANTS.SET_USER_MARKERS, payload: Object.values(data.map)});
            if (data.currentFrame && data.currentFrame.vehicle_position){
                this.dispatch({type: CONSTANTS.SET_VEHICLE_POSITION, payload: data.currentFrame.vehicle_position});
            }
            if (data.currentFrame && data.currentFrame.detections){ 
                this.dispatch({type: CONSTANTS.SET_SENSOR_DETECTIONS, payload: data.currentFrame.detections});
            }
            
        });
        this.socket.on('Updated_Tracker_Marker', (data) => {
            this.dispatch({type: CONSTANTS.UPDATE_USER_MARKER, payload: data});
        });
        this.socket.on('Tracker_Removed', (id) => {
            this.dispatch({type: CONSTANTS.REMOVE_USER_MARKER, payload: id});
        });
        this.socket.on('Frame_Update', (data) => {
            this.dispatch({type: CONSTANTS.SET_VEHICLE_POSITION, payload: data.vehicle_position});
            this.dispatch({type: CONSTANTS.SET_SENSOR_DETECTIONS, payload: data.detections});
        });

    }

    updateLocation = ({latitude, longitude}) => {
        this.socket.emit('Location_Change', {latitude, longitude});
    }
}