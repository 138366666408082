import {useSelector, useDispatch} from 'react-redux';
import Socket from '../helpers/socket';
import {useMemo, useEffect, useRef} from 'react';
import {MapContainer, TileLayer, Marker, useMap} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import sensor from '../reducers/sensor';

const humanIcon = L.divIcon({
    className: 'spartan-dot-icon',
    html: '<div class="spartan-dot"></div>',
    iconSize: [5, 5],
    iconAnchor: [2.5, 2.5]
});

const vehicleIcon = L.divIcon({
    className: 'spartan-vehicle-icon',
    html: '<div class="spartan-vehicle"></div>',
    iconSize: [10, 5],
    iconAnchor: [5, 5]
});

const sensorIcon = L.divIcon({
    className: 'spartan-sensor-icon',
    html: '<div class="spartan-sensor"></div>',
    iconSize: [8, 8],
    iconAnchor: [4, 4]
});

const MovingMarkers = ({humanTracks, sensorTracks, vehiclePosition}) => {
    const map = useMap();
    const humansRef = useRef([]);
    const sensorsRef = useRef([]);
    const vehicleRef = useRef(null);
    console.log("HUMAN TRACKS", humanTracks)
    console.log("SENSOR TRACKS", sensorTracks)
    console.log("VEHICLE POSITION", vehiclePosition)
    console.log("----------")
    useEffect(() => {
        //Clear all markers
        humansRef.current.forEach((marker) => {
            map.removeLayer(marker);
        });

        sensorsRef.current.forEach((marker) => {
            map.removeLayer(marker);
        });

        if(vehicleRef.current){
            map.removeLayer(vehicleRef.current);
        }

        //Set New Center Position of Map to Vehicle Position or a default location
        if(vehiclePosition && vehiclePosition.latitude && vehiclePosition.longitude){
            console.log("HERERERER")
            map.setView([vehiclePosition.latitude, vehiclePosition.longitude], 19);
        } else if (humanTracks && humanTracks.length > 0){
            console.log("HERER")
            map.setView([humanTracks[0].latitude, humanTracks[0].longitude], 19);
        } else if (sensorTracks && sensorTracks.length > 0){ 
            console.log("HERER")
            map.setView([sensorTracks[0].latitude, sensorTracks[0].longitude], 19);
        } else {
            console.log("ENDING IT HERER")
            map.setView([0, 0], 19);
        }

        //Add Human + Sensor Tracks
        if (humanTracks){ 
            humanTracks.forEach((track) => {
                console.log(track)
                const marker = L.marker([track.latitude, track.longitude], {icon: humanIcon}).addTo(map);
                humansRef.current.push(marker);
            });
        }

        if (sensorTracks){
    
            sensorTracks.forEach((track) => {
                const marker = L.marker([track.latitude, track.longitude], {icon: sensorIcon}).addTo(map);
                sensorsRef.current.push(marker);
            });
        }
        

        //Add Vehicle Position
        if(vehiclePosition && vehiclePosition.latitude && vehiclePosition.longitude){
            vehicleRef.current = L.marker([vehiclePosition.latitude, vehiclePosition.longitude], {icon: vehicleIcon}).addTo(map);
        }

    }, [humanTracks, sensorTracks, vehiclePosition]);
    return null;
}

export default function Dashboard  ()  {
    const dispatch = useDispatch();
    const socket = useMemo(() => new Socket(dispatch), [dispatch]);
    const humanTracks = useSelector((state) => state.sensor.humanTracks);
    const sensorTracks = useSelector((state) => state.sensor.sensorDetections);
    const vehiclePosition = useSelector((state) => state.sensor.vehiclePosition);
    

    return (
        <div>
            <MapContainer center={[0, 0]} zoom={19} style={{height: "100vh", width: '100vw'}}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                />
                <MovingMarkers humanTracks={humanTracks} sensorTracks={sensorTracks} vehiclePosition={vehiclePosition} />
            </MapContainer>
        </div>
    )
}