import './App.css';
import {useState, useMemo} from 'react';
import Socket from './helpers/socket';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch()
  const socket = useMemo(() => new Socket(dispatch), [dispatch]);
  const [isActive, setIsActive] = useState(false);

  const activate = () => {
    setIsActive(true);
    if ('navigator' in window && 'geolocation' in navigator) {
      navigator.geolocation.watchPosition((position) => {
        const {latitude, longitude} = position.coords;
        console.log(position.coords);
        socket.updateLocation({latitude, longitude});
      }, (error) => {
        console.error(error);
      }, {enableHighAccuracy: true, timeout: 2000, maximumAge: 0});
    }
    
  }
  return (
    <div className="text-center">
      <h3>Activate your GPS</h3>
      <p>Remember to keep this screen on during the full course of the demo</p>
      {!isActive ? <button onClick={activate}>Activate GPS</button> : <p>GPS is active</p>}
    </div>
  );
}

export default App;
