import {combineReducers} from 'redux';
import * as CONSTANTS from '../constants/actions';

export default combineReducers({
    humanTracks: (state = [], action) => {
        switch(action.type){
            case CONSTANTS.SET_USER_MARKERS:
                return action.payload;
            case CONSTANTS.UPDATE_USER_MARKER:
                const newState = [...state];
                const index = newState.findIndex((marker) => marker.id === action.payload.id);
                if(index !== -1){
                    newState[index] = action.payload;
                } else {
                    newState.push(action.payload);
                }
                return newState;
            case CONSTANTS.REMOVE_USER_MARKER:
                return state.filter((marker) => marker.id !== action.payload);
            default:
                return state;
        }
    },
    sensorDetections: (state = [], action) => {
        switch(action.type){
            case CONSTANTS.SET_SENSOR_DETECTIONS:
                return action.payload;
            default:
                return state;
        }
    },
    vehiclePosition: (state = {latitude: 0, longitude: 0}, action) => {
        switch(action.type){
            case CONSTANTS.SET_VEHICLE_POSITION:
                return action.payload;
            default:
                return state;
        }
    },
});